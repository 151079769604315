<template>
    <div>
        <v-card  :loading="savingState">
      <v-toolbar flat>
        <v-toolbar-title> Preview Booking: {{ bookingRequest.orderNo }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn outlined small color="green" style="text-transform: none" @click="acceptOrder" class="mx-1"><v-icon small left>check</v-icon> Accept</v-btn>
        <v-btn outlined small color="red" style="text-transform: none" @click="rejectOrder" class="mx-1"><v-icon small left>cancel</v-icon>Reject</v-btn>

        <v-btn text @click="$emit('close')">X</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <v-card
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
              outlined
            >
              <v-card-text>
                <v-list dense class="text-center">
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">settings</v-icon>Settings
                  </v-subheader>
                  <v-list dense class="text-left">
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>category</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >Status</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip small :color="statusColor(bookingRequest.orderStatus)">
                              <v-icon small left>{{ statusIcon(bookingRequest.orderStatus) }}</v-icon>
                              <span style="text-transform: none; color: white">
                                {{ bookingRequest.orderStatus }}
                              </span>
                            </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>import_export</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Movement Type</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          small
                          :color="
                            bookingRequest.movementType == 'EXPORT'
                              ? 'deep-orange'
                              : 'blue darken-2'
                          "
                        >
                          <v-icon small left>import_export</v-icon>
                          <span style="text-transform: none; color: white">
                            {{ $Format.capitalizeFirstLetter(bookingRequest.movementType) }}
                          </span>
                        </v-chip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>airline_stops</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >Cargo Service Type</v-list-item-title
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                            <v-chip small color="indigo">
                              <v-icon small left>import_export</v-icon>
                              <span style="text-transform: none; color: white">
                                {{ bookingRequest.serviceType }}
                              </span>
                            </v-chip>
                      </v-list-item-action>
                    </v-list-item>
               
                    <v-list-item style="height: 35px">
                      <v-list-item-action>
                        <v-icon color="grey" small>mode_of_travel</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Mode of Transport</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                            <v-chip small color="blue-grey darken-2">
                              <v-icon
                                small
                                left
                                v-if="bookingRequest.modeOfTransport == 'OCEAN'"
                                >directions_boat</v-icon
                              >
                              <v-icon
                                small
                                left
                                v-else-if="bookingRequest.modeOfTransport == 'AIR'"
                                >flight</v-icon
                              >
                              <v-icon
                                small
                                left
                                v-else-if="bookingRequest.modeOfTransport == 'RAIL'"
                                >train</v-icon
                              >
                              <v-icon
                                small
                                left
                                v-else-if="bookingRequest.modeOfTransport == 'ROAD'"
                                >local_shipping</v-icon
                              >

                              <span style="text-transform: none; color: white">
                                {{
                                  $Format.capitalizeFirstLetter(bookingRequest.modeOfTransport)
                                }}
                              </span>
                            </v-chip>
                         
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-list dense class="text-center">
                    <v-subheader style="font-size: 16px">
                      <v-icon class="mr-2" color="grey">group</v-icon>Parties
                    </v-subheader>
                    <v-list dense class="text-left">
                      <v-list-item v-if="bookingRequest.customer">
                        <v-list-item-action>
                          <v-avatar v-if="bookingRequest.customer.logo" color="white">
                            <v-img :src="bookingRequest.customer.logo" contain></v-img>
                          </v-avatar>
                          <v-avatar v-else color="secondary">
                            <h2>{{ bookingRequest.customer.name.charAt(0) }}</h2>
                          </v-avatar>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ bookingRequest.customer.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Selected Customer
                          </v-list-item-subtitle>
                        </v-list-item-content>
                       
                      </v-list-item>

                      <v-list-item v-if="bookingRequest.customer" :key="profileKey">
                        <v-list-item-action>
                          <v-icon color="secondary">handshake</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="bookingRequest.consigneeProfile">
                            {{ bookingRequest.consigneeProfile.systemReference }}
                          </v-list-item-title>
                          <v-list-item-title v-else> - </v-list-item-title>
                          <v-list-item-subtitle>
                            Shipment Profile
                          </v-list-item-subtitle>
                        </v-list-item-content>
                       
                      </v-list-item>
                      <v-list-item
                        v-if="bookingRequest.consigneeProfile"
                      >
                        <v-list-item-action>
                          <v-icon v-if="bookingRequest.dealTerm" color="secondary"
                            >settings</v-icon
                          >
                          <v-icon v-else color="primary"
                            >add_circle_outline</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content v-if="!bookingRequest.dealTerm">
                          <span style="font-size: 16px"
                            >Select Profile Configuration</span
                          >
                        </v-list-item-content>
                        <v-list-item-content v-else>
                          <v-list-item-title>
                            {{ bookingRequest.dealTerm.incoTerm }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Profile Configuration
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="!bookingRequest.consigneeProfile"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="!bookingRequest.incoTerm ? 'secondary' : 'success'"
                            >swap_horiz</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-if="bookingRequest.incoTerm">
                            {{ bookingRequest.incoTerm }}
                          </v-list-item-title>
                          <v-list-item-title v-else>-</v-list-item-title>
                          <v-list-item-subtitle>
                            Inco Term
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <div v-if="bookingRequest.orderCustomer">
                        <v-list-item
                          :disabled="
                            bookingRequest.consigneeProfile != undefined ||
                            bookingRequest.consigneeProfile != null
                          "
                        >
                          <v-list-item-action>
                            <v-icon class="mt-0 pt-0" color="grey"
                              >directions_boat_filled</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="bookingRequest.shipper">
                              {{ bookingRequest.shipper.name }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle v-if="bookingRequest.onBehalfShipper">
                              On behalf of {{ bookingRequest.onBehalfShipper.name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon x-small color="grey" class="mr-1"
                                >label</v-icon
                              >
                              Shipper
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          :disabled="
                            bookingRequest.consigneeProfile != undefined ||
                            bookingRequest.consigneeProfile != null
                          "
                        >
                          <v-list-item-action>
                            <v-icon class="mt-0 pt-0" color="grey"
                              >fast_forward</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="bookingRequest.forwarder">
                              {{ bookingRequest.forwarder.name }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle
                              v-if="bookingRequest.onBehalfForwarder"
                            >
                              On behalf of {{ bookingRequest.onBehalfForwarder.name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon x-small color="grey" class="mr-1"
                                >label</v-icon
                              >
                              Forwarder
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                          :disabled="
                            bookingRequest.consigneeProfile != undefined ||
                            bookingRequest.consigneeProfile != null
                          "
                        >
                          <v-list-item-action>
                            <v-icon class="mt-0 pt-0" color="grey"
                              >call_received</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="bookingRequest.consignee">
                              {{ bookingRequest.consignee.name }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle
                              v-if="bookingRequest.onBehalfConsignee"
                            >
                              On behalf of {{ bookingRequest.onBehalfConsignee.name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon x-small color="grey" class="mr-1"
                                >label</v-icon
                              >
                              Consignee
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          :disabled="
                            bookingRequest.consigneeProfile != undefined ||
                            bookingRequest.consigneeProfile != null
                          "
                        >
                          <v-list-item-action>
                            <v-icon class="mt-0 pt-0" color="grey"
                              >shopping_cart</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="bookingRequest.buyer">
                              {{ bookingRequest.buyer.name }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle v-if="bookingRequest.onBehalfBuyer">
                              On behalf of {{ bookingRequest.onBehalfBuyer.name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon x-small color="grey" class="mr-1"
                                >label</v-icon
                              >
                              Buyer
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-list>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
              outlined
            >
              <v-card-text>
                <v-list dense>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">location_on</v-icon>
                    Origin & Destination</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                  >
                    <v-list-item-action>
                      <v-icon
                        :color="!bookingRequest.originCountry ? 'secondary' : 'success'"
                        >public</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="bookingRequest.originCountry">
                        {{ bookingRequest.originCountry.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Origin Country
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          contain
                          v-if="bookingRequest.originCountry"
                          :src="`https://cdn.loglive.io/flags/4x3/${bookingRequest.originCountry.iso2.toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item >
                    <v-list-item-action>
                      <v-icon
                        :color="
                          !bookingRequest.portOfLoadValue ? 'secondary' : 'success'
                        "
                        >anchor</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="bookingRequest.portOfLoadCity">
                        {{ bookingRequest.portOfLoadCity }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Port of Load
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip v-if="bookingRequest.portOfLoadValue" pill small outlined>
                        <v-avatar size="24" left>
                          <v-img
                            contain
                            v-if="bookingRequest.portOfLoadValue"
                            :src="`https://cdn.loglive.io/flags/4x3/${bookingRequest.portOfLoadValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        {{ bookingRequest.portOfLoadValue }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item >
                    <v-list-item-action>
                      <v-icon
                        :color="
                          !bookingRequest.portOfDischargeValue ? 'secondary' : 'success'
                        "
                        >anchor</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="bookingRequest.portOfDischargeCity">
                        {{ bookingRequest.portOfDischargeCity }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Port of Discharge
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip
                        v-if="bookingRequest.portOfDischargeValue"
                        pill
                        small
                        outlined
                      >
                        <v-avatar size="24" left>
                          <v-img
                            contain
                            v-if="bookingRequest.portOfDischargeValue"
                            :src="`https://cdn.loglive.io/flags/4x3/${bookingRequest.portOfDischargeValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        {{ bookingRequest.portOfDischargeValue }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon
                        :color="
                          !bookingRequest.finalDestinationValue ? 'secondary' : 'success'
                        "
                        >location_on</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="bookingRequest.finalDestinationCity">
                        {{ bookingRequest.finalDestinationCity }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Final Destination
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip
                        v-if="bookingRequest.finalDestinationValue"
                        pill
                        small
                        outlined
                      >
                        <v-avatar size="24" left>
                          <v-img
                            contain
                            v-if="bookingRequest.finalDestinationValue"
                            :src="`https://cdn.loglive.io/flags/4x3/${bookingRequest.finalDestinationValue
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                        {{ bookingRequest.finalDestinationValue }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item
                  >
                    <v-list-item-action>
                      <v-icon
                        :color="
                          !bookingRequest.destinationCountry ? 'secondary' : 'success'
                        "
                        >public</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-if="bookingRequest.destinationCountry">
                        {{ bookingRequest.destinationCountry.name }}
                      </v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>
                        Destination Country
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-avatar size="32">
                        <v-img
                          contain
                          v-if="bookingRequest.destinationCountry"
                          :src="`https://cdn.loglive.io/flags/4x3/${bookingRequest.destinationCountry.iso2.toLowerCase()}.svg`"
                        ></v-img>
                      </v-avatar>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px"
                    ><v-icon class="mr-2" color="grey">mode_of_travel</v-icon
                    >Transport Details</v-subheader
                  >
                  <v-divider></v-divider>
                  <v-list-item
                    v-if="bookingRequest.modeOfTransport == 'OCEAN'"
                  >
                    <v-list-item-action v-if="!bookingRequest.shippingLine">
                      <v-icon color="secondary">directions_boat</v-icon>
                    </v-list-item-action>
                    <v-list-item-avatar v-else>
                      <v-avatar
                        color="white"
                        size="36"
                        v-if="bookingRequest.shippingLine"
                      >
                        <v-img
                          v-if="bookingRequest.shippingLine && bookingRequest.shippingLine.logo"
                          :src="bookingRequest.shippingLine.logo"
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content v-if="bookingRequest.vessel">
                      <v-list-item-title>
                        {{ bookingRequest.vessel.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <u
                          style="cursor: pointer"
                          v-if="bookingRequest.mainCarriageConveyanceNumber"
                          >Voyage No:
                          {{ bookingRequest.mainCarriageConveyanceNumber }}</u
                        >
                        <u
                          style="cursor: pointer"
                          v-else
                          >No Voyage No</u
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <v-list-item-content>
                        <v-list-item-title>Add Vessel</v-list-item-title>
                        <v-list-item-subtitle v-if="bookingRequest.orderStatus != 'Planned'"
                          ><i>(Required)</i></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="bookingRequest.modeOfTransport == 'OCEAN'"
                  >
                    <v-list-item-action v-if="!bookingRequest.shippingLine">
                      <v-icon color="secondary">directions_boat</v-icon>
                    </v-list-item-action>
                    <v-list-item-avatar v-else>
                      <v-avatar
                        color="white"
                        size="36"
                        v-if="bookingRequest.shippingLine"
                      >
                        <v-img
                          v-if="bookingRequest.shippingLine && bookingRequest.shippingLine.logo"
                          :src="bookingRequest.shippingLine.logo"
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-if="bookingRequest.shippingLine">{{
                        bookingRequest.shippingLine.name
                      }}</v-list-item-title>
                      <v-list-item-title v-else>-</v-list-item-title>
                      <v-list-item-subtitle>Shipping Line</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <div v-if="bookingRequest.orderStatus != 'Planned'">
                        <v-list-item >
                          <v-list-item-action>
                            <v-icon :color="bookingRequest.etd ? 'success' : 'secondary'"
                              >today</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="bookingRequest.etd">
                              {{ formatDate(bookingRequest.etd) }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Estimated Departure <i>(Required)</i>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item >
                          <v-list-item-action>
                            <v-icon :color="bookingRequest.eta ? 'success' : 'secondary'"
                              >today</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="bookingRequest.eta">
                              {{ formatDate(bookingRequest.eta) }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Estimated Arrival
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                  </div>
                  <div v-else>
                    <v-list-item>
                          <v-list-item-action>
                            <v-icon :color="bookingRequest.eta ? 'success' : 'secondary'"
                              >today</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="bookingRequest.plannedWeek">
                              Week {{ bookingRequest.plannedWeek }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Planned Week
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-action>
                            <v-icon :color="bookingRequest.eta ? 'success' : 'secondary'"
                              >today</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title v-if="bookingRequest.plannedYear">
                              Week {{ bookingRequest.plannedYear }}
                            </v-list-item-title>
                            <v-list-item-title v-else> - </v-list-item-title>
                            <v-list-item-subtitle>
                              Planned Year
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                   
                  </div>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4">
            <v-card
              style="
                border-radius: 20px;
                background-color: var(--v-toolbar-lighten1) !important;
              "
              outlined
            >
              <v-card-text>
                <v-list dense class="text-center">
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">list_alt</v-icon>Order
                    Details
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-row class="mt-2">
                      <v-col cols="12" md="6" class="my-1 py-0">
                        <v-text-field
                          dense
                          hide-details
                          style="height: 40px"
                          label="Carrier Ref No"
                          disabled
                          v-model="bookingRequest.carrierReferenceNumber"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="my-1 py-0">
                        <v-text-field
                          dense
                          hide-details
                          style="height: 40px"
                          label="Shipper Ref No"
                          disabled
                          v-model="bookingRequest.shipperReferenceNo"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="my-1 py-0">
                        <v-text-field
                          dense
                          style="height: 40px"
                          hide-details
                          label="Consignee Ref No"
                          disabled
                          v-model="bookingRequest.consigneeReferenceNo"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-subheader>Regime Code</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item v-if="bookingRequest.regime" >
                        <v-list-item-action class="mr-0 pr-0">
                          <v-btn text rounded
                              > {{
                                    bookingRequest.regime.code
                                  }}</v-btn>
                                  <!-- <br> -->
                        </v-list-item-action>
                        <v-list-item-content class="ml-0 pl-0">
                          <v-list-item-subtitle>
                            <v-icon color="#007d5c" small class="mr-2"
                                  >thermostat</v-icon
                                > Set Temp: {{`${bookingRequest.regime.setPointTemp}°C`}}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle  v-if="bookingRequest.regime.protocol">
                            <v-icon color="#9d904a" small class="mr-2"
                                  >thermostat</v-icon
                                > Protocol: {{`${bookingRequest.regime.protocol}°C`}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-else >
                        <v-list-item-content class="text-center">
                          <v-list-item-title style="color: grey">
                            No Regime Code
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">
                    <v-icon class="mr-2" color="grey">shopping_cart</v-icon
                    >Order Items <v-spacer></v-spacer>
                    <!-- <v-btn icon color="primary" @click="addOrderItem()"
                      ><v-icon>add_circle_outline</v-icon></v-btn
                    > -->
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list dense class="text-left">
                    <v-list-item v-if="containers.length == 0">
            <v-list-item-content class="text-center">
              <span style="font-size: 12px; color: grey">No Order Items</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(container) in containers"
            :key="container.id"
            
          >
            <v-list-item-action>
              <v-progress-circular
                :value="container.assignedProgress"
                size="20"
                :color="
                  container.assignedProgress < 20
                    ? 'red'
                    : container.assignedProgress < 50
                    ? 'orange'
                    : container.assignedProgress < 100
                    ? 'blue'
                    : 'success'
                "
              ></v-progress-circular>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ container.ctoNo }}
              </v-list-item-title>
              <v-list-item-subtitle style="font-size: 11px">
                {{ container.assignedStock }} Assigned Pallets
              </v-list-item-subtitle>
              <v-list-item-subtitle
                style="font-size: 11px"
                v-if="getContainerDescription(container.containerTypeCode)"
              >
                {{ getContainerDescription(container.containerTypeCode) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
              <ul>
                <li
                  style="font-size: 10px"
                  v-for="item in container.orderCargoItems"
                  :key="item.id"
                >
                  <span>
                    {{ $Format.capitalizeFirstLetter(item.product.name) }}
                    <span v-if="item.varietyProduct"
                      >:
                      {{
                        $Format.capitalizeFirstLetter(item.varietyProduct.name)
                      }}</span
                    >
                    <br />
                    <span style="color: grey">{{ productSpec(item) }}</span>
                  </span>
                </li>
              </ul>
            </v-list-item-content>
            <!-- <v-list-item-action>
              <v-container>
                <v-row align="center" justify="center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon small @click="addOrderItem(container)">
                        <v-icon small>edit</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon small color="teal" v-on="on" @click="cloneItem(container)">
                        <v-icon small>content_copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Create Copy</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon small color="red" v-on="on" @click="removeOrderItem(container, index)">
                        <v-icon small>delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </v-row>
              </v-container>
            </v-list-item-action> -->
          </v-list-item>
                    <!-- <v-list-item v-if="bookingRequest.bookingContainerItems.length == 0">
                      <v-list-item-content>
                        <span style="font-size: 12px; color: grey"
                          >No items added.</span
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-for="container in bookingRequest.bookingContainerItems"
                      :key="container.tempId"
                    >
                      <v-list-item-action>
                        <v-chip
                          >{{ container.quantity }} x
                          {{ container.containerTypeCode }}</v-chip
                        >
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list dense class="my-0 py-0">
                          <v-list-item
                            v-for="item in container.orderCargoItems"
                            :key="item.tempId"
                          >
                            <v-list-item-action>
                              {{ item.quantity }} {{ formatBaseUnit(container.baseUnit)}}(s)
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-chip small
                                  ><v-icon small left>category</v-icon>
                                  {{
                                    $Format.capitalizeFirstLetter(item.product.name)
                                  }}
                                  <span v-if="item.varietyProduct"
                                    >:
                                    {{
                                      $Format.capitalizeFirstLetter(
                                        item.varietyProduct.name
                                      )
                                    }}</span
                                  ></v-chip
                                >
                              </v-list-item-title>
                              <v-list-item-subtitle
                                style="font-size: 11px"
                                class="text-wrap"
                              >
                                {{ productSpec(item) }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-list-item-content>
                    </v-list-item> -->
                  </v-list>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
    name: "PreviewBookingRequest",
    props: {
        bookingRequest: {
            type: Object,
            required: true
        },
        containers: {
            type: Array,
            required: true
        },
        containerTypes: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            orderStatus: [
            { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Planned", icon: "schedule", color: "#F57C00" },
      { name: "Open", icon: "pending", color: "#2196F3" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      // { name: "Cancelled", icon: "cancel", color: "#F44336" }
    ],
            productQuantityTypes: [
      { value: "BG", text: "Bag" },
      { value: "TT", text: "Bag, tote" },
      { value: "BA", text: "Barrel" },
      { value: "BL", text: "Bale" },
      { value: "BI", text: "Bin" },
      { value: "BX", text: "Box" },
      { value: "VL", text: "Bulk, liquid" },
      { value: "VR", text: "Bulk, solid" },
      { value: "BH", text: "Bunch" },
      { value: "BE", text: "Bundle" },
      { value: "CT", text: "Carton" },
      { value: "CS", text: "Case" },
      { value: "CN", text: "Container" },
      { value: "CR", text: "Crate" },
      { value: "DR", text: "Drum" },
      { value: "FB", text: "Flexibag" },
      { value: "JT", text: "Jutebag" },
      { value: "LU", text: "Lug" },
      { value: "PK", text: "Package" },
      { value: "PA", text: "Packet" },
      { value: "PX", text: "Pallet" },
      { value: "PT", text: "Pot" },
      { value: "PO", text: "Pouch" },
      { value: "RO", text: "Roll" },
      { value: "SH", text: "Sachet" },
      { value: "SA", text: "Sack" },
      { value: "SI", text: "Skid" },
      { value: "PU", text: "Tray" },
      { value: "VI", text: "Vial" },
    ],
    savingState: false,
        }
    },
    methods: {
    formatBaseUnit(type) {
      let result = this.productQuantityTypes.find((x) => x.value == type);
      return result ? result.text : "";
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    getContainerDescription(code) {
      let result = null;
      if (code) {
        let findDescription = this.containerTypes.find(
          (x) => x.typeCode == code
        );
        result = findDescription ? findDescription.shortDescription : null;
      }
      return result;
    },
    productSpec(item) {
      let keys = [
        { key: "puc", value: "PUC" },
        { key: "phc", value: "PHC" },
        { key: "packCode", value: "PACK" },
        { key: "grade", value: "Grade" },
        { key: "countSize", value: "Count" },
      ];
      let result = [];
      for (let i = 0; i < keys.length; i++) {
        let find = item.orderProductSpecifications ?? []
        find = find.filter(x=>x.key == keys[i].key)
        if (find.length>0) {
          result.push(keys[i].value + ": " + find.map(x=>x.value).join(', '));
        }
      }
      return result.join(" - ");
    },
    statusColor(status) {
      let color = this.orderStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return color ? color.color : "grey";
    },
    statusIcon(status) {
      let find = this.orderStatus.find(
        (x) => x.name.toLowerCase() === status.toLowerCase()
      );
      return find ? find.icon : "";
    },
    async acceptOrder(){
        this.savingState = true;
        await this.$API.acceptBookingRequest(this.bookingRequest.id)
        this.savingState = false
        this.$message.success("Booking Request Accepted")
        this.viewBooking(this.bookingRequest)
        this.$emit("refresh")
        this.$emit("close")
    },
    async rejectOrder(){
        this.savingState = true;
        await this.$API.rejectBookingRequest(this.bookingRequest.id)
        this.savingState = false
        this.$message.success("Booking Request Rejected")
        this.$emit("refresh")
        this.$emit("close")
    },
    viewBooking(item) {
      this.$router.push({
        path: "/booking/" + item.systemReference,
      });
    },
    }
}
</script>